import { motion, useInView,  } from "framer-motion"
import { useRef, useEffect, useState } from "react"

function Projects() {
  const [idx, setIdx] = useState(0)
  const ref = useRef(null)
  const isInView = useInView(ref)

  const [scrollDirection, setScrollDirection] = useState(0)

  const teamData = [
    {
      name: "Next Knock",
      role: "An Online Platform for hostellites to find a hostel.",
      image: "/assets/nk.png",
      linkedin: "https://nextknock.com/",
    },
    {
      name: "Fleet tracker",
      role: "A Cross Platform Mobile App for Fleet Managers",
      image: "/projects/fleettracker.png",
    },
    {
      name: "Ecommerce Store",
      role: "An Ice-Cream Store to order ice-cream online.",
      image: "/projects/wc.png",
    },
    {
      name: "Nikes",
      role: "An app that uses BlockChain APIs",
      image: "/projects/nikes.png",
    },
    {
      name: "Employee-GPT",
      role: "Experience the new exposure of AI with GPT 3.5",
      image: "/assets/egpt.png",
    },
    {
      name: "Automated AI model training Web App",
      role: "A Flask web app that will do preprocessing and train model automatically and give you the result.",
      image: "/assets/aitask.png",
    },
    {
        name: "E-Learn Pay Per Hour",
        role: "A web app for teachers to teach students on pay per hour basis.",
        image: "/assets/elearn.png",
    },
    {
        name: "Qubitrix",
        role: "A web app which provide automated calculations for quantum computing qubit calculations.",
        image: "/assets/qubit.png",
    },
    {
        name: "COM_ISO",
        role: "An AI web app for checking the iso standards of a company.",
        image: "/assets/iso.png",
    },
    {
        name: "DASH Visualization",
        role: "An automated flask web app for showing different visualization of data.",
        image: "/assets/dash.png",
    },
    

      
    
    // Add more team members here
  ]

  return (
    <div className="dark:bg-black bg-white">
      <div ref={ref} className="mx-auto   px-4">
        <motion.div
          className="flex flex-col"
          // variants={fade}
          // animate={isInView ? "show" : "hidden"}
        >
          <h1
            className={` top-0 left-0 right-0 text-center 
            dark:text-white text-blue-500 text-3xl py-4
        ${idx === teamData.length - 1 && !isInView ? "" : "sticky"}`}
          >
            Some Work We have Done
          </h1>

          <div className="flex w-full items-start gap-20 ">
            <div className="w-full py-[50vh]">
              <ul>
                {teamData.map((team, index) => (
                  <li key={index}>
                    <FeatureTitle
                      setIdx={setIdx}
                      idx={index}
                      setScrollDirection={setScrollDirection}
                      className="lg:text-5xl max-lg:text-2xl text-white"
                      id={index}
                    >
                      {team}
                    </FeatureTitle>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sticky top-[25%] max-lg:top-[0%] flex h-screen w-full items-center">
              <div
                className="relative aspect-square w-full rounded-2xl
                "
              >
                {/* {features.map((feature) => ( */}
                <motion.div
                  className={`absolute inset-0 h-full w-full
          `}
                  key={idx}
                  initial={
                    scrollDirection > 0
                      ? { y: 300, opacity: 0 }
                      : { y: -300, opacity: 0 }
                  }
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      ease: "easeOut",
                      duration: 0.25,
                    },
                  }}
                >
                  <img
                    src={`${teamData[idx].image}`}
                    className="h-[60vh] max-lg:h-full w-full object-cover rounded-2xl"
                    alt=""
                  />
                </motion.div>
                {/* ))} */}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Projects

export const FeatureTitle = ({
  children,
  id,
  setIdx,
  idx,
  setScrollDirection,
}) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { margin: "-50% 0px -50% 0px" })

  useEffect(() => {
    // console.log("isInView", isInView)
    // console.log("id", id)
    // console.log(idx)
    if (!isInView) {
      // scroll up
      setScrollDirection(-1)
      return
    }
    setScrollDirection(1)
    setIdx(parseInt(idx))
  }, [isInView, id])
  return (
    <h1
      key={id}
      id={id}
      ref={ref}
      className={`py-16 font-heading lg:text-5xl max-lg:text-2xl transition-colors
          ${isInView ? "dark:text-white text-gray-600" : "dark:text-gray-600 text-gray-300"}`}
    >
      {children.name}
      <br />
      <motion.div
        className="flex flex-wrap gap-2"
        animate={id === idx && isInView ? "visible" : "hidden"}
        variants={{
          hidden: { opacity: 0, x: 20 },
          visible: {
            opacity: 1,
            x: 0,
          },
          transition: {
            duration: 0.5,
          },
        }}
      >
        <p className="text-sm">{children.role}</p>

        {/* <a href={children.linkedin} target="_blank" rel="noreferrer">
          <img src="/linkedin.png" className="w-5 h-5 object-cover" alt="" />
        </a> */}
      </motion.div>
    </h1>
  )
}
