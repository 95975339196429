import { motion } from "framer-motion"
import { delayText, titleAnim } from "../animation"
import { useState } from "react"
function AnimatingText() {
  const data = ["Websites", "Apps"]
  const [showOther, setShowOther] = useState(false)
  return (
    <motion.div className="p-1 w-max h-32 max-md:h-14 overflow-hidden max-lg:text-center  max-lg:w-full">
      <motion.div
       variants={
        titleAnim
      }
      onAnimationComplete={()=>{
        console.log("done")
        setShowOther(true)
      }}
      >
        <motion.h1
          className="max-md:hidden text-blue-500 text-9xl font-bold "
          initial={{ y: -10 }}
          animate={{ y: -145 }}
          transition={{
            delay: 2.5,
            duration: 0.5,
            repeatDelay: 1.5,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          Webs
          <br />
          {showOther && "Apps"}
        </motion.h1>
        <motion.h1
          className="md:hidden text-blue-500 text-5xl font-bold "
          initial={{ y: 0 }}
          animate={{ y: -50 }}
          transition={{
            delay: 2.5,

            duration: 0.5,
            repeatDelay: 1.5,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          Webs
          <br />
          {showOther && "Apps"}
        </motion.h1>
      </motion.div>
    </motion.div>
  )
}

export default AnimatingText
