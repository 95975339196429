import React, { useState, useEffect }  from 'react'
import { motion } from 'framer-motion';

export default function Statistics() {
    const [projectCount, setProjectCount] = useState(0);
  const [hoursCount, setHoursCount] = useState(0);
  const [customersCount, setCustomersCount] = useState(0);

  useEffect(() => {
    if (projectCount < 50) {
      const projectInterval = setInterval(() => {
        setProjectCount((prevCount) => prevCount + 1);
      }, 50);

      return () => {
        clearInterval(projectInterval);
      };
    }

    if (hoursCount < 23000) {
      const hoursInterval = setInterval(() => {
        setHoursCount((prevCount) => prevCount + 191);
      }, 50);

      return () => {
        clearInterval(hoursInterval);
      };
    }

    if (customersCount < 50) {
      const customersInterval = setInterval(() => {
        setCustomersCount((prevCount) => prevCount + 1);
      }, 50);

      return () => {
        clearInterval(customersInterval);
      };
    }
  }, [projectCount, hoursCount, customersCount]);

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };
  return (
    <>
    <div class="container mb-4 mx-auto">
      <div class="flex justify-center">
          <h1 class="text-3xl md:text-4xl lg:text-4xl font-bold text-center">
              About Us | Artificial Intelligence
              <span class="text-blue-400 ml-1 mr-1">Company</span>
              in Pakistan
          </h1>
      </div>
    </div>

    <section className="statistics-section mb-8 flex items-center">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          <motion.div
            className="p-6 flex flex-col justify-center rounded-lg dark:bg-neutral-700 bg-neutral-300 shadow-md"
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
          >
            <h2 className="text-xl text-center font-semibold">
              <i className="mt-3 fas fa-code text-2xl"></i>
              <span className="ml-2">{projectCount}+</span>
            </h2>
            <h2 className="mt-2 text-center text-lg">Projects</h2>
          </motion.div>
          <motion.div
            className="p-6 dark:bg-neutral-700 bg-neutral-300 rounded-lg shadow-md"
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
          >
            <h2 className="text-xl text-center font-semibold">
              <i className="mt-3 fas fa-clock text-2xl"></i>
              <span className="ml-2">{hoursCount}+</span>
            </h2>
            <h2 className="mt-2 text-center text-lg">Development Hours</h2>
          </motion.div>
          <motion.div
            className="p-6 dark:bg-neutral-700 bg-neutral-300 rounded-lg shadow-md"
            variants={fadeInVariants}
            initial="hidden"
            animate="visible"
          >
            <h2 className="text-xl text-center font-semibold">
              <i className="mt-3 fas fa-user text-2xl"></i>
              <span className="ml-2">{customersCount}+</span>
            </h2>
            <h2 className="mt-2 text-center text-lg">Happy Customers</h2>
          </motion.div>
        </div>
      </div>
    </section>

    <div className="dark:bg-neutral-700 bg-neutral-300  flex items-center">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="p-4">
            <h1 className="text-4xl font-bold">
              Our <span className="text-blue-400">Mission</span>
            </h1>
            <p className="font-semibold text-lg mt-2">
              Our mission is to help our clients to achieve their goals by providing them with the best possible solutions and services. We are committed to providing the most efficient and effective solutions to our clients. We are committed to providing the most efficient and effective solutions to our clients.
            </p>
          </div>
          <div className="p-4">
            <h1 className="text-4xl font-bold">
              Our <span className="text-blue-400">Vision</span>
            </h1>
            <p className="font-semibold text-lg mt-2">
              Our vision is to be the most trusted and respected professional services firm recognized by our clients for delivering excellence.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="mt-8 flex items-center justify-center">
        <div className="w-full">
            <h1 className="text-center font-bold text-2xl lg:text-3xl">
            We're Going To <span className="text-blue-400">Become Partners</span>  For The Long Run.
            </h1>
            <img src="/assets/bg.png" alt="" className="w-full" />
        </div>
    </div>

    <div className=" mt-5 items-center">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="lg:col-span-1 flex flex-col items-center justify-center">
          <h4 className="text-center font-sans font-bold text-blue-400 text-xl mb-2">LET'S COLLABORATE</h4>
          <h1 className="text-center font-semibold text-2xl">Got A Project?</h1>
          <h1 className="text-center font-semibold text-2xl">Let's Talk.</h1>
        </div>
        <div className="lg:col-span-1 flex items-center justify-center">
          <button
            className="btn font-semibold text-xl py-2 px-6 bg-blue-400 hover:bg-blue-500 text-white rounded-full"
          >
            Schedule a Call
          </button>
        </div>
      </div>
    </div>

    <div style={{height:"70px"}}></div>
    </>
  )
}
