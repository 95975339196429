import React, { useRef, useState } from "react"
import { motion, Variants, useInView } from "framer-motion"
import { lineAnim } from "../../../animation"
import "../css/home.css"

export default function Info() {
  const chooseus = useRef(null)
  const isInView = useInView(chooseus)
  return (
    <>
      <div
        ref={chooseus}
        class="flex dark:bg-black items-center justify-center"
      >
        <div class="text-center">
          <h4 class="text-6xl text-center font-bold text-blue-500 py-2">
            WHY CHOOSE US
          </h4>
          <h2 class="font-extrabold mt-5">
            We Deliver Best Solution Architecture For Your Problems
          </h2>
        </div>
      </div>
      <div className="dark:bg-black">
        <div className="w-2/3 mx-auto">
          <motion.div
            variants={lineAnim}
            animate={isInView ? "show" : "hidden"}
            className="h-2  bg-blue-500 "
          ></motion.div>
        </div>
      </div>
      <div className="dark:bg-black bg-gray-200 py-5">
        <div class="flex items-center justify-center">
          <div class="container mt-5">
            <div class="flex flex-wrap justify-center">
              <div class="card mt-2 custom-card mx-2 max-w-sm ">
                <div class="card-body">
                  <div class="box flex flex-col items-center">
                    <div class="flex justify-center">
                      <img
                        src="/assets/svgs/person.svg"
                        alt=""
                        class="w-12 h-12"
                      />
                    </div>
                    <h4 class="dark:text-white mt-3 text-xl text-center">Efficient Team</h4>
                    <p class="dark:text-white  text-center">
                      We have the most efficient and highly skilled
                      professionals in the field of AI and machine learning.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card mt-2 custom-card mx-2 max-w-sm ">
                <div class="card-body">
                  <div class="box flex flex-col items-center">
                    <div class="flex justify-center">
                      <img
                        src="/assets/svgs/quality.svg"
                        alt=""
                        class="w-12 h-12"
                      />
                    </div>
                    <h4 class="dark:text-white mt-3 text-xl text-center">
                      Top-Quality Results
                    </h4>
                    <p class="dark:text-white text-center">
                      Providing our clients with top-quality results is the
                      first and foremost priority of Devupix.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card mt-2 custom-card mx-2 max-w-sm ">
                <div class="card-body">
                  <div class="box flex flex-col items-center">
                    <div class="flex justify-center">
                      <img
                        src="/assets/svgs/creative.svg"
                        alt=""
                        class="w-12 h-12"
                      />
                    </div>
                    <h4 class="dark:text-white mt-3 text-xl text-center">Creative Ideas</h4>
                    <p class="dark:text-white text-center">
                      We have trained our employees to think out of the box so
                      they can come up with the most creative ideas.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card mt-2 custom-card mx-2 max-w-sm ">
                <div class="card-body">
                  <div class="box flex flex-col items-center">
                    <div class="flex justify-center">
                      <img
                        src="/assets/svgs/performance.svg"
                        alt=""
                        class="w-12 h-12"
                      />
                    </div>
                    <h4 class="dark:text-white mt-3 text-xl text-center">
                      Performance Optimized
                    </h4>
                    <p class="dark:text-white text-center">
                      As a cutting-edge company in Pakistan, our client's
                      satisfaction is a key point in our project execution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
