import Projects from "./components/projects"
import StaggerRow from "./components/stagger_row"
import HomeHero from "./components/hero"
import Reviews from "./components/reviews"
import ChooseUs from "./components/choose_us"
import ContactUs from "./components/contact_us"
import Info from "./components/info"

function HomePage() {
  return (
    <>
      {/* Hero Section */}
      <HomeHero />

      <Info />
      {/* TechStacks */}
      <StaggerRow />
      {/* reviews */}
      <Reviews />
      {/* choose us */}
      <ChooseUs />

      {/* Projects  */}
      <Projects />

      <ContactUs />
    </>
  )
}

export default HomePage
