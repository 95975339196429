import React from 'react'
import Showcase from '../../home/components/showcase'

export default function Mainsection() {
  return (
    <>
    <div className='dark:bg-black dark:text-white'>
    <div class="pt-6 flex flex-col items-center justify-center">
        <h1 class="dark:text-white mt-5 text-5xl font-semibold text-center">
            Meet Our
            <span class="dark:text-white text-black font-black"> Leaders</span>
        </h1>
        <p class="mt-3 text-center text-dark-400 text-2xl">
            Devupix has built and manages a team of world-class developers to bring
            your vision to life
        </p>
    </div>
    <div class="flex flex-col items-center justify-center">
        <div class="container">
            <div class="flex flex-col-reverse lg:flex-row">
                
                <div class="w-full lg:w-1/2">
                    <img src="/assets/team-goals.png" alt="" class="w-full max-w-full h-auto" />
                </div>
                <div class="w-full lg:w-1/2">
                    <div class="p-8 mt-10 lg:mt-40">
                        <div class="about-text">
                            <p class='text-xl'>Turning Ideas into Reality: Meet the Minds Behind the Magic</p>
                            <h1 class='text-4xl font-bold'>
                                Elevate your digital experiences with the experts at{" "}
                                <span class="text-cyan-400">DevuPix</span>, delivering tomorrow's
                                solutions today.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img class="hidden lg:block absolute -z-10 h-full right-0 top-0" src="/splash.svg" alt="" />
    </div>

    <Showcase />
    </div>
    </>
  )
}
