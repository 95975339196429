import { Routes, Route,  } from "react-router-dom"
import TeamsPage from "../pages/teams/teams"
import HomePage from "../pages/home/home"
import About from "../pages/About/About"

export const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/team" element={<TeamsPage />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  )
}
