import React from 'react'
import AboutSection from './components/AboutSection'
import Statistics from './components/Statistics'

export default function About() {
  return (
    <>
    <div className='dark:bg-black dark:text-white'>
    <AboutSection />
    <Statistics />
    </div>
    </>
  )
}
