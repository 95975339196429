import React, { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPaintBrush,
  faInfinity,
  faShieldVirus,
  faBrain,
  faComputer,
  faMobileAndroid,
  faCode,
  faFileWord,
  faNetworkWired,
} from "@fortawesome/free-solid-svg-icons"
import "@fortawesome/fontawesome-free/css/all.min.css" // Make sure to import the CSS
import { motion, useInView } from "framer-motion"
import {
  lineAnim,
  photoAnim,
  photoAnimDelay,
  scrollReveal,
} from "../../../animation"

function ChooseUs() {
  const ref = useRef(null)
  const isInView = useInView(ref)
  return (
    <>
      <div
        ref={ref}
        className="min-h-screen flex flex-col 
        justify-center overflow-hidden py-5 dark:bg-black"
      >
        <h1 className="text-5xl max-md:text-xl my-2 text-center  font-bold text-blue-500 ">
          Technology Consulting & Development Services
        </h1>
        <div className="w-2/3 mx-auto">
          <motion.div
            variants={lineAnim}
            animate={isInView ? "show" : "hidden"}
            className="h-2 mb-2 bg-blue-500 "
          ></motion.div>
        </div>
        <motion.div className="grid grid-cols-12 lg:gap-10 lg:px-80 ">
          <ChooseUsItem count={0} icon={faBrain} text={"ML/AI"} />
          <ChooseUsItem count={1} icon={faCode} text={"Web Development"} />
          <ChooseUsItem
            count={0}
            icon={faMobileAndroid}
            text={"Mobile Development"}
          />
          <ChooseUsItem
            count={1}
            icon={faFileWord}
            text={"WordPress Development"}
          />
          <ChooseUsItem
            count={0}
            icon={faInfinity}
            text={"Software Automation"}
          />
          <ChooseUsItem
            count={1}
            icon={faComputer}
            text={"Custom Software Development"}
          />
        </motion.div>
      </div>
    </>
  )
}

export default ChooseUs
function ChooseUsItem({ icon, text, count = 0 }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 0.5 })
  return (
    <motion.div
      variants={photoAnimDelay(count)}
      // whileHover={{ scale: 1.1, transition: { duration: 0.3, ease:"easeOut" } }}
      ref={ref}
      animate={isInView ? "show" : "hidden"}
      className="col-span-6 max-md:col-span-12"
    >
      <div className="py-10 w-full rounded">
        <div className=" flex flex-col justify-center text-blue-500">
          <FontAwesomeIcon className="mt-3" icon={icon} size="2xl" />
          <h4 className="mt-3 text-center font-semibold text-lg max-md:text-sm">
            {text}
          </h4>
        </div>
      </div>
    </motion.div>
  )
}
