import React, { useState, useEffect } from "react"
import { Link, NavLink } from "react-router-dom"
// import Logo from "../assets/logo.svg";
import { useContext } from "react"
import ThemeContext from "../lib/themecontext"
import { AnimatePresence, motion } from "framer-motion"
import { containerVars, menuVars, mobileLinkVars } from "../animation"

export const Header = () => {
  const [isDropDownOpen, setDropDownOpen] = useState(false)
  // const [darkMode, setDarkMode] = useState(true);
  const { darkMode, setDarkMode } = useContext(ThemeContext)

  const [open, setOpen] = useState(false)

  const navItems = [
    { text: "Home", to: "/" },
    { text: "Teams", to: "/team" },
    {text:"About",to:"/about"}
  ]

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleDropdownToggle = () => {
    setDropDownOpen(!isDropDownOpen)
    console.log(isDropDownOpen)
    // set event listener on screen & if clicked outside dropdown then close it

    // when clicked on dropdown then remove event listener
  }

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode))

    if (darkMode) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
  }, [darkMode])

  return (
    <nav className=" dark:bg-black  bg-transparent m-auto  ">
      {" "}
      {/*bg-cyan-900*/}
      <div className="flex items-center font-medium  justify-between px-10">
        <NavLink to={"/"} className="flex items-center gap-2 p-2.5">
          <img
            src={"/devupix.png"}
            alt="Devupix"
            className=" md:cursor-pointer h-12"
          />
          <span className="text-2xl dark:text-gray-100  text-gray-600">
            Devupix
          </span>
        </NavLink>
        <div className="flex items-center gap-5">
          {/*<div>bg-cyan-900</div>*/}

          <ul className="flex max-lg:hidden  items-center font-sans font-semibold dark:text-gray-100  text-black">
            <li>
              <NavLink
                to="/"
                className="py-2 px-4 inline-block rounded-md 
                lg:text-white hover:text-black text-gray-600 
                dark:hover:bg-gray-800 hover:bg-gray-300
                transition-all duration-300 ease-in-out
                "
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/team"
                className="py-2 px-4 inline-block rounded-md 
                lg:text-white hover:text-black text-gray-600 
                dark:hover:bg-gray-800 hover:bg-gray-300
                transition-all duration-300 ease-in-out"
              >
                Teams
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className="py-2 px-4 inline-block rounded-md 
                lg:text-white hover:text-black text-gray-600 
                dark:hover:bg-gray-800 hover:bg-gray-300
                transition-all duration-300 ease-in-out"
              >
                About
              </NavLink>
            </li>
            {/*/dropdown list*/}
            <li className="relative">
              <div
                onClick={handleDropdownToggle}
                className="flex  px-4 cursor-pointer items-center transition-all duration-300 ease-in-out gap-5 rounded-md dark:hover:bg-gray-800 hover:bg-gray-300 "
              >
                <div className="flex gap-2">
                  <h1
                    to="/services"
                    className="py-2 inline-block   lg:text-white hover:text-black text-gray-600 
                "
                  >
                    Services
                  </h1>
                  <button>
                    <svg
                      className=" w-2.5 h-3 text-gray-6 pt-1"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              {isDropDownOpen && (
                <div className="dropdown-menu absolute dark:bg-black bg-white rounded-md shadow-lg  z-10  border-2 dark:border-zinc-900  border-gray-400  ">
                  <ul className=" divide-y dark:divide-zinc-700 divide-gray-400">
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300">
                      <NavLink
                        to="/"
                        className=" dark:hover:bg-gray-800 hover:bg-gray-300  "
                      >
                        NLP Processing & Sentimental Analysis{" "}
                      </NavLink>
                    </li>
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300 ">
                      <NavLink to="/" className="">
                        Benefits of Recommender System
                      </NavLink>
                    </li>
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                      <NavLink to="/" className="  ">
                        AI & ML{" "}
                      </NavLink>
                    </li>
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                      <NavLink to="/" className="  ">
                        Frontend Development{" "}
                      </NavLink>
                    </li>
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                      <NavLink to="/" className="  ">
                        Backend Development{" "}
                      </NavLink>
                    </li>
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                      <NavLink to="/" className="  ">
                        API & Regulation{" "}
                      </NavLink>
                    </li>
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                      <NavLink to="/" className="  ">
                        Cross Platform App Development{" "}
                      </NavLink>
                    </li>
                    <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                      <NavLink to="/" className="  ">
                        SEO & Digital Marketing{" "}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            {/*/dropdown list ended*/}
          </ul>

          <button
            onClick={() => setDarkMode(!darkMode)}
            type="button"
            data-toggle-dark="light"
            className="flex items-center p-2 mr-2 text-xs font-medium text-gray-700 bg-white rounded-lg border border-gray-200  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-black focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            {darkMode ? (
              <svg
                aria-hidden="true"
                data-toggle-icon="sun"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg
                aria-hidden="true"
                data-toggle-icon="moon"
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
              </svg>
            )}
          </button>

          <div
            className="cursor-pointer lg:hidden text-md text-white"
            onClick={toggleMenu}
          >
            <img
              src={`${darkMode ? "/hamburger.svg" : "hamburger-dark.svg"}`}
              className="w-10 h-10 object-cover "
              alt=""
            />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="z-20 fixed left-0 top-0 w-full h-screen origin-top  dark:bg-black bg-white dark:text-white text-gray-600 p-10"
          >
            <div className="flex h-full flex-col">
              <div className="flex justify-between">
                <h1 className="text-lg text-white">Devupix</h1>
                <p
                  className="cursor-pointer text-md dark:text-white text-gray-600"
                  onClick={toggleMenu}
                >
                  Close
                </p>
              </div>
              <motion.div
                variants={containerVars}
                initial="initial"
                animate="open"
                exit="initial"
                className="flex flex-col h-full justify-center font-lora items-center gap-4 "
              >
                {navItems.map((link, index) => {
                  return (
                    <div key={index} className="overflow-hidden">
                      <MobileNavLink
                        key={index}
                        title={link.text}
                        href={link.to}
                        toggleMenu={toggleMenu}
                      />
                    </div>
                  )
                })}
                <a className="overflow-hidden ">
                  <div
                    onClick={handleDropdownToggle}
                    className="flex px-4 cursor-pointer items-center transition-all duration-300 ease-in-out gap-5 rounded-md dark:hover:bg-gray-800 hover:bg-gray-300 "
                  >
                    <div className="flex gap-2">
                      <h1
                        to="/services"
                        className="py-2 inline-block   lg:text-white hover:text-black text-gray-600 
                    "
                      >
                        Services
                      </h1>
                      <button>
                        <svg
                          className=" w-2.5 h-3 text-gray-6 pt-1"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                    {isDropDownOpen && (
                      <div className="dropdown-menu absolute right-0 mt-2 dark:bg-black bg-white rounded-md shadow-lg z-10 border-2 dark:border-zinc-900 border-gray-400">
                        <ul className="divide-y dark:divide-zinc-700 divide-gray-400 max-h-[160px] overflow-y-auto">
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300">
                            <NavLink
                              to="/"
                              className=" dark:hover:bg-gray-800 hover:bg-gray-300  "
                            >
                              NLP Processing & Sentimental Analysis{" "}
                            </NavLink>
                          </li>
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300 ">
                            <NavLink to="/" className="">
                              Benefits of Recommender System
                            </NavLink>
                          </li>
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                            <NavLink to="/" className="  ">
                              AI & ML{" "}
                            </NavLink>
                          </li>
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                            <NavLink to="/" className="  ">
                              Frontend Development{" "}
                            </NavLink>
                          </li>
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                            <NavLink to="/" className="  ">
                              Backend Development{" "}
                            </NavLink>
                          </li>
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                            <NavLink to="/" className="  ">
                              API & Regulation{" "}
                            </NavLink>
                          </li>
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                            <NavLink to="/" className="  ">
                              Cross Platform App Development{" "}
                            </NavLink>
                          </li>
                          <li className="px-4 py-2 dark:hover:bg-gray-800 hover:bg-gray-300  ">
                            <NavLink to="/" className="  ">
                              SEO & Digital Marketing{" "}
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    )}
                </a>
              </motion.div>     
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

const MobileNavLink = ({ title, href, toggleMenu }) => {
  return (
    <motion.div variants={mobileLinkVars} className="text-5xl uppercase ">
      <Link onClick={() => toggleMenu()} to={href}>
        {title}
      </Link>
    </motion.div>
  )
}
