import { motion, useInView } from "framer-motion"
import { fastPageAnimation, photoAnim, scrollReveal, scrollRevealUp } from "../../../animation"
import { useRef, useState,useEffect } from "react"

function Reviews() {
  const ref = useRef(null)
  const [threshold, setThreshold] = useState(0.5);
  const isInView = useInView(ref, { amount: threshold});

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setThreshold(0.2); // Adjust the threshold for small screens
      } else {
        setThreshold(0.5); // Default threshold for larger screens
      }
    };

    handleResize(); // Set initial threshold based on window width

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div ref={ref} className="dark:bg-black overflow-hidden py-2">
        <h1 className=" max-md:text-xl text-5xl font-bold my-2 text-center text-blue-500">Reviews</h1>
        <motion.div
          variants={scrollRevealUp}
          animate={isInView ? "visible" : "hidden"}
          className="grid grid-cols-12 h-full gap-2"
        >
          <ReviewItem />
          <ReviewItem />
          <ReviewItem />
        </motion.div>
      </div>
    </>
  )
}

export default Reviews

function ReviewItem() {
  return (
    <>
      <motion.div

        className="col-span-4 max-lg:col-span-6 max-md:col-span-12 mx-2 bg-blue-500 h-full rounded-2xl p-8"
      >
        <div className="flex flex-col gap-2">
          <div className="stars flex gap-2">
            <img
              src="/assets/svgs/star.png"
              className="w-10 h-10 object-cover"
              alt=""
            />
            <img
              src="/assets/svgs/star.png"
              className="w-10 h-10 object-cover"
              alt=""
            />
            <img
              src="/assets/svgs/star.png"
              className="w-10 h-10 object-cover"
              alt=""
            />
            <img
              src="/assets/svgs/star.png"
              className="w-10 h-10 object-cover"
              alt=""
            />
          </div>
          <h1 className="max-md:text-sm text-lg text-white font-bold">
            " " Maskdev Studio was a pleasure to work with. They took the time to understand my requirements, delivered on time, made changes promptly and exceeded my expectations. Very professional and delivered well ahead of time! Will return for his services. ""
          </h1>
          <div className="flex gap-2 items-center">
            <div className="bg-white w-max h-max rounded-full">
              <img
                src="/assets/svgs/star.png"
                className="w-10 h-10 object-cover"
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <h1 className="text-md text-white font-bold">John Doe</h1>
              <h2 className="text-sm text-white font-bold">CEO</h2>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}
