import AnimatingText from "../../../components/animating_text"
import { AnimatePresence, motion } from "framer-motion"
import {
  fade,
  fastPageAnimation,
  photoAnim,
  photoAnimDelay,
  scrollReveal,
  titleAnim,
} from "../../../animation"
import { useState } from "react"
import { useEffect } from "react"

function HomeHero() {
  const items = [
    "projects/fleettracker.png",
    "projects/wc.png",
    "projects/nikes.png",
    "projects/wc_web.png",
  ]
  const [current, setCurrent] = useState(items[0])
  const [nextImage, setNextImage] = useState(null)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentIndex = items.indexOf(current)
      const nextIndex = (currentIndex + 1) % items.length
      const nextImage = new Image()

      nextImage.onload = () => {
        // Switch to the new image only when it is fully loaded
        setCurrent(items[nextIndex])
      }

      nextImage.src = items[nextIndex]
      setNextImage(nextImage)
    }, 5000)

    // Cleanup the interval and reset nextImage on component unmount
    return () => {
      clearInterval(intervalId)
      setNextImage(null)
    }
  }, [current, items])

  return (
    <>
      <motion.div
        exit={"exit"}
        variants={fastPageAnimation}
        initial={`hidden`}
        animate={`show`}
        className="flex flex-wrap dark:bg-black bg-transparent 
        max-lg:flex-col max-lg:justify-center max-lg:items-center max-lg:gap-10
        justify-between px-20 py-10 min-h-screen"
      >
        <motion.h1 className="flex flex-1 h-max overflow-hidden  flex-col gap-2 max-lg:text-center">
          <div className="overflow-hidden lg:w-fit lg:h-fit p-1 ">
            <motion.h1 variants={titleAnim} className="text-lg text-gray-500">
              We love making
            </motion.h1>
          </div>
          <AnimatingText />
          <div className="overflow-hidden lg:w-fit lg:h-fit p-1 ">
            <motion.h1 variants={titleAnim} className=" text-gray-600 text-7xl">
              that'll <span className="text-blue-500">stun</span> you.
            </motion.h1>
          </div>
          <div className="overflow-hidden lg:w-fit lg:h-fit p-1 ">
            <motion.h1 variants={titleAnim} className="text-2xl text-gray-600">
              We are a company of Web, App, SEO & AI <br /> developers with rich
              portfolio
            </motion.h1>
          </div>
          <motion.div
            variants={fade}
            className="flex gap-1 max-lg:justify-center"
          >
            <img
              className="w-10 cursor-pointer object-cover
              transition-all duration-300 ease-out
              hover:scale-100
              scale-90
              "
              src="/assets/github.png"
              alt=""
            />
            <img
              className="w-10 cursor-pointer object-cover 
              transition-all duration-300 ease-out
              hover:scale-100
              scale-90"
              src="/assets/x.webp"
              alt=""
            />
            <img
              className="w-10 cursor-pointer rounded-lg object-cover
              transition-all duration-300 ease-out
              hover:scale-100
              scale-90
              "
              src="/assets/linkedin.png"
              alt=""
            />
          </motion.div>
          <div>
            <motion.button
              variants={fade}
              className="bg-gray-500  relative text-white px-8 py-2 
            rounded-xl mt-4
            hover:bg-blue-500 transition-all duration-500 ease-out
            "
            >
              <h1 className="">Get in touch</h1>
            </motion.button>
          </div>
        </motion.h1>

        
          <motion.div
            className="relative  md:flex-1 max-md:mt-2"
            key={current} // Add key prop to force re-mount on image change
            animate={{ x: [0, -10, 10, -10, 0] }}
            transition={{ duration: 0.4, ease: "easeOut" }}
          >
            <AnimatePresence>
            <img
              className="h-[50vh] object-cover w-full rounded-xl"
              src={current}
              alt=""
            />
            </AnimatePresence>
          </motion.div>
      </motion.div>

      <img
        className="max-lg:hidden absolute -z-10 h-full right-0 top-0"
        src="/splash.svg"
        alt=""
      />
    </>
  )
}

export default HomeHero
