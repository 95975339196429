import { motion, useInView, useScroll } from "framer-motion"
import { useRef, useEffect, useState } from "react"
import { fade, scrollReveal } from "../../../animation"

function Showcase() {
  const [idx, setIdx] = useState(0)
  const ref = useRef(null)
  const isInView = useInView(ref)

  const [scrollDirection, setScrollDirection] = useState(0)

  const teamData = [
    {
      name: "Saim Sheikh",
      role: "CEO",
      image: "/assets/teams/saim.jpg",
      linkedin: "https://www.linkedin.com/in/saim-sheikh-2460711b7/",
    },
    {
      name: "Farhat Abbas",
      role: "CTO",
      image: "/assets/teams/farhat.jpg",
      linkedin: "https://www.linkedin.com/in/farhat-abbas/",
    },
    {
      name: "Abdul Moeed",
      role: "Java Developer",
      image: "/assets/teams/moeed.jpeg",
      linkedin: "https://www.linkedin.com/in/abdul-moeed-cs/",
    },
    {
      name: "Javeria Rehman",
      role: "Flutter Developer",
      image: "/assets/teams/javeria.jpg",
      linkedin: "https://www.linkedin.com/in/thejaveria/",
    },
    {
      name: "Ahmer Malik",
      role: "Flutter Developer",
      image: "/assets/teams/ahmer.jpeg",
      linkedin: "https://www.linkedin.com/in/ahmer-malik-478836256/",
    },
    // {
    //   name: "Alishbah Khalid",
    //   role: "Flutter Developer",
    //   image: "/assets/teams/alishbah.jpg",
    //   linkedin: "https://www.linkedin.com/in/alishbah-khalid",
    // },
    {
      name: "Shayan Ahmad",
      role: "Flutter Developer",
      image: "/assets/teams/shayan.jpg",
      linkedin: "https://www.linkedin.com/in/shayan-alvi-96b30b20b/",
    },
    {
      name: "Rafia Zulqarnain",
      role: "SEO Specialist",
      image: "/assets/teams/blank_female.svg",
      linkedin: "https://www.linkedin.com/in/rafia-zulqarnain-abbasi-3711ba25a/",
    },
    {
      name: "Akasha Noor",
      role: "SEO Executive",
      image: "/assets/teams/blank_female.svg",
      linkedin: "https://www.linkedin.com/in/akasha-noor-04aa9b252/",
    },
    {
      name: "Shumaim Kiyani",
      role: "SEO & Content Writer",
      image: "/assets/teams/blank_female.svg",
      linkedin: "https://www.linkedin.com/in/shumaim-kiyani",
    },
    {
      name: "Asfar",
      role: "Sr. Web Developer",
      image: "/assets/teams/Asfar.PNG",
      linkedin: "https://www.linkedin.com/in/asfar-munir-asfi-bb5237234/",
    },
    {
      name: "SherJan",
      role: "Jnr. Frontend Developer",
      image: "/assets/teams/sherjan.jpeg",
      linkedin: "https://www.linkedin.com/in/sher-jan",
    },
    {
      name: "Toqeer Ahmad",
      role: "Jnr. Frontend Developer",
      image: "/assets/teams/toqeer.jpg",
      linkedin: "https://www.linkedin.com/in/toqeer-ahmad-91b546261",
    },
    {
      name: "Mohammad Moin",
      role: "Jnr. Frontend Developer",
      image: "/assets/teams/moin.jpeg",
      linkedin: "https://www.linkedin.com/in/muhammad-moin-b30a78265",
    },
    {
      name: "Aleena Ahsan",
      role: "Jnr. Frontend Developer",
      image: "/assets/teams/blank_female.svg",
      linkedin: "https://www.linkedin.com/in/member2/",
    },
    // Add more team members here
  ]

  return (
    <div className="dark:bg-black bg-white">
      <div ref={ref} className="mx-auto lg:w-3/4 max-lg:w-full px-4">
        <motion.div
          className="flex flex-col"
          // variants={fade}
          // animate={isInView ? "show" : "hidden"}
        >
          <h1
            className={` top-0 left-0 right-0 text-center 
            dark:text-white text-blue-500 text-3xl py-4
        ${idx === teamData.length - 1 && !isInView ? "" : "sticky"}`}
          >
            Our Talented Team Members
          </h1>

          <div className="flex w-full items-start gap-20 ">
            <div className="w-full py-[50vh]">
              <ul>
                {teamData.map((team, index) => (
                  <li key={index}>
                    <FeatureTitle
                      setIdx={setIdx}
                      idx={index}
                      setScrollDirection={setScrollDirection}
                      className="lg:text-5xl max-lg:text-2xl text-white"
                      id={index}
                    >
                      {team}
                    </FeatureTitle>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sticky top-0 flex h-screen w-full items-center">
              <div
                className="relative aspect-square w-full rounded-2xl
                "
              >
                {/* {features.map((feature) => ( */}
                <motion.div
                  className={`absolute inset-0 h-full w-full
          `}
                  key={idx}
                  initial={
                    scrollDirection > 0
                      ? { y: 300, opacity: 0 }
                      : { y: -300, opacity: 0 }
                  }
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      ease: "easeOut",
                      duration: 0.25,
                    },
                  }}
                >
                  <img
                    src={`${teamData[idx].image}`}
                    className="h-full w-full object-cover rounded-2xl"
                    alt=""
                  />
                </motion.div>
                {/* ))} */}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Showcase

export const FeatureTitle = ({
  children,
  id,
  setIdx,
  idx,
  setScrollDirection,
}) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { margin: "-50% 0px -50% 0px" })

  useEffect(() => {
    console.log("isInView", isInView)
    console.log("id", id)
    console.log(idx)
    if (!isInView) {
      // scroll up
      setScrollDirection(-1)
      return
    }
    setScrollDirection(1)
    setIdx(parseInt(idx))
  }, [isInView, id])
  return (
    <p
      key={id}
      id={id}
      ref={ref}
      className={`py-16 font-heading lg:text-5xl max-lg:text-2xl transition-colors
          ${isInView ? "dark:text-white text-gray-600" : "dark:text-gray-600 text-gray-300"}`}
    >
      {children.name}
      <br />
      <motion.div
        className="flex flex-wrap gap-2"
        animate={id === idx && isInView ? "visible" : "hidden"}
        variants={{
          hidden: { opacity: 0, x: 20 },
          visible: {
            opacity: 1,
            x: 0,
          },
          transition: {
            duration: 0.5,
          },
        }}
      >
        <p className="text-sm">{children.role}</p>

        <a href={children.linkedin} target="_blank" rel="noreferrer">
          <img src="/linkedin.png" className="w-5 h-5 object-cover" alt="" />
        </a>
      </motion.div>
    </p>
  )
}
