import { motion, Variants, useInView } from "framer-motion"
import React, { useRef, useState } from "react"
import { lineAnim } from "../../../animation"

function StaggerRow() {
  const frontEnd = [
    { imgSrc: "/stacks/angular.png" },
    { imgSrc: "/stacks/js.png" },
    { imgSrc: "/stacks/react.png" },
    { imgSrc: "/stacks/ts.png" },
    // {imgSrc: "/stacks/css.png"},
  ]
  const backEnd = [
    { imgSrc: "/stacks/NodeJS.png" },
    { imgSrc: "/stacks/react-fire.svg" },
    { imgSrc: "/stacks/django.png" },
  ]
  const mobDev = [{ imgSrc: "/stacks/flutter.svg" }]
  const [activeData, setActiveData] = useState([
    ...frontEnd,
    ...backEnd,
    ...mobDev,
  ])

  const staggerRow = useRef(null)
  const isInView = useInView(staggerRow)
  return (
    <>
      {/* <div className="flex gap-2 ">
      <button>Frontend</button>
      <button>Backend</button>
      <button>Mobile</button>
      <button>AI</button>
    </div> */}
      <div className="dark:bg-black">
      <h1 className="text-6xl text-center font-bold text-blue-500 py-2">
        Frameworks & Tools
      </h1>
      <div className="w-2/3 mx-auto">
        <motion.div
          variants={lineAnim}
          animate={isInView ? "show" : "hidden"}
          className="h-2 mb-2 bg-blue-500 "
        ></motion.div>
      </div>
      <motion.div
        ref={staggerRow}
        className="flex flex-wrap  gap-2 justify-center overflow-hidden"
        hidden="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={{
          visible: { transition: { staggerChildren: 0.1 } }, // Adjust the stagger delay as needed
        }}
      >
        {activeData.map((item, idx) => (
          <ImageItem key={idx} imgSrc={item.imgSrc} />
        ))}
      </motion.div>
      </div>
    </>
  )
}

function ImageItem({ imgSrc }) {
  return (
    <motion.img
      variants={{
        hidden: { scale: 0, x: -50 },
        visible: {
          scale: 1,
          x: 0,
        },
        transition: {
          duration: 0.2,
        },
      }}
      className="w-32 object-contain rounded-lg"
      src={imgSrc}
      alt=""
    />
  )
}

export default StaggerRow
