import { faBuilding, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, useInView } from "framer-motion"
import {
  fade,
  fastPageAnimation,
  pageAnimation,
  photoAnim,
  scrollReveal,
  titleAnim,
} from "../../../animation"
import { useRef } from "react"

function ContactUs() {
  const ref = useRef(null)
  const isInView = useInView(ref)

  return (
    <motion.div
      ref={ref}
      variants={fastPageAnimation}
      animate={isInView ? "show" : "hidden"}
      className="bg-blue-100 dark:bg-black min-h-screen"
      name="contactSection"
    >
      <div className="grid grid-cols-12 gap-6 py-10">
        <div className="col-span-12 mx-10 overflow-hidden w-max h-max">
          <motion.h1
            variants={titleAnim}
            className=" font-medium text-5xl max-md:text-xl dark:text-white text-black"
          >
            Let's build something <br /> awesome together
          </motion.h1>
        </div>

        <form className="col-span-6 max-md:col-span-12 px-10">
          <motion.div variants={photoAnim} className="flex flex-col gap-5">
            <motion.input
              type="text"
              placeholder="Name"
              className="p-2 h-10 rounded-md"
            />
            <motion.input
              type="text"
              placeholder="Email"
              className="p-2 h-12 rounded-md
              border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 peer 
              "
            />

            <motion.textarea
              placeholder="Message"
              className="p-2 h-32 rounded-md"
            />
            <motion.button
              className="bg-blue-500 text-white px-5
             py-2 w-max rounded-3xl"
            >
              Send Message
            </motion.button>
          </motion.div>
        </form>

        <motion.div
          variants={photoAnim}
          className="col-span-6 max-md:col-span-12"
        >
          <div className="p-5 flex flex-col items-center ">
            <div className="flex flex-row items-center">
              <div className="p-3 w-16 flex justify-center bg-white shadow rounded ">
                <FontAwesomeIcon color="#3b82f6" icon={faBuilding} size="2x" />
              </div>
              <div className="ml-4 dark:text-white">
                <h3>Visit Us</h3>
                <p>Lahore, Pakistan 🇵🇰</p>
              </div>
            </div>
          </div>

          <div className="p-5 flex flex-col items-center ">
            <div className="flex flex-row items-center">
              <div className="p-3 bg-white shadow rounded w-16 flex justify-center">
                <FontAwesomeIcon color="#3b82f6" icon={faPhone} size="2x" />
              </div>
              <div className="ml-4 dark:text-white">
                <h3>Or drop us a line</h3>
                <p>saimsheikh@devupix.com</p>
                <p>PK +92 322 9289895</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default ContactUs
