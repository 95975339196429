export const pageAnimation = {
  hidden: {
    opacity: 0,
    y: 300,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.25,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
}

export const fastPageAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.5, ease: "easeOut" },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
}

export const titleAnim = {
  hidden: {
    y: -200,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
  },
  show: {
    y: 0,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
  },
}

export const fade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.75 },
  },
}
export const photoAnim = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.35,
      ease: "easeInOut",
    },
  },
}

export const photoAnimDelay = (index) => ({
  hidden: { scale: 0 }, // Start at scale 1
  show: {
    scale: [0, 1.2, 1], // Animate from 1 to 1.2 and back to 1
    transition: {
      ease: "easeInOut",
      duration: 0.35,
      delay: index * 0.25, // Adjust the delay as needed
    },
  },
  // exit: { scale: 1 }, // Return to scale 1 when exiting
})

export const lineAnim = {
  hidden: { width: "30%", },
  show: { width: "100%",  transition: { duration: 1 } },
}

export const slider = {
  hidden: {
    x: "-130%",
    skew: "45deg",
  },
  show: {
    x: "100%",
    skew: "0deg",
    transition: { ease: "easeOut", duration: 1 },
  },
}

export const sliderContainer = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { staggerChildren: 0.25, ease: "easeOut" } },
}

export const scrollReveal = {
  hidden: { opacity: 0, scale: 1.2, transition: { duration: 0.5 } },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
}

export const scrollRevealUp = {
  hidden: { y:100, opacity: 0, scale: 1.2, transition: { duration: 0.5 } },
  show: {
    y:0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1.5,
      ease: "easeOut",
    },
  },
}



export const hover = {
  show: {
    scale: 1.1,
    zIndex: 100,
    transition: {
      duration: 0.5,
    },
  },
}

export const delayText = {
  hidden: {
    opacity: 0,
    y: 150,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.45,
      ease: "easeOut",
    },
  },
}

// navbar animations
export const menuVars = {
  initial: {
    scaleY: 0,
  },
  animate: {
    scaleY: 1,
    transition: {
      duration: 0.5,
      ease: [0.12, 0, 0.39, 0],
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
}
export const containerVars = {
  initial: {
    transition: {
      staggerChildren: 0.09,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.09,
      staggerDirection: 1,
    },
  },
}

export const mobileLinkVars = {
  initial: {
    y: "30vh",
    transition: {
      duration: 0.5,
      ease: [0.37, 0, 0.63, 1],
    },
  },
  open: {
    y: 0,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 0.7,
    },
  },
}
