import React from 'react'

export default function AboutSection() {
  return (
    <>
    <div class="flex items-center justify-center">
        <div class="container">
            <div class="flex flex-col lg:flex-row">
                <div class="w-full lg:w-1/2">
                    <div class="p-8 mt-10 lg:mt-40">
                        <p class="text-2xl font-semibold">We are proud of the work we've done</p>
                        <h1 class="text-6xl font-bold">
                            We Are {" "}
                            <span class="text-blue-400">Experts</span> Full Stack Developer & AI Freaks
                        </h1>
                    </div>
                </div>
                <div class="w-full lg:w-1/2">
                    <img src="/assets/about-us.png" alt="devupix" class="w-full max-w-full h-auto" />
                </div>
            </div>
        </div>
        <img class="hidden lg:block absolute -z-10 h-full right-0 top-0" src="/splash.svg" alt="" />
    </div>



    </>
  )
}
