import { useState } from "react"
import { Header } from "./components/header"
import ThemeContext from "./lib/themecontext"
import { AllRoutes } from "./routes/all_routes"
import { Footer } from "./components/Footer"

function App() {
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  )
  
  return (
    <>
      <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
        <Header />
        <AllRoutes />     
        <Footer />   
      </ThemeContext.Provider>
    </>
  )
}

export default App
